import $ from 'jquery';
$(document).ready(function () {
  // Add smooth scrolling to all links
  $('a').on('click', function (event) {
    // Make sure this.hash has a value before overriding default behavior
    let hasLocalHash = this.hash !== '';
    if (hasLocalHash) {
      hasLocalHash = !this.host || this.host === window.location.host;
      hasLocalHash = hasLocalHash && this.pathname === window.location.pathname
    }

    if (hasLocalHash) {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;
      if ($(hash).length === 0) return;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top,
        },
        800,
        function () {
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        },
      );
    } // End if
  });
});
