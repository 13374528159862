import $ from 'jquery';
import videojs from "video.js";
import axios from "axios";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
// import "videojs-overlay";
import chromecastPlugin from "@silvermine/videojs-chromecast";
import { parseQueryParams } from '../js/shared/utils'

require('./shared/layout');

var setOffline = function () {
  if ($("#status-overlay")) return;

  var overlay = document.createElement("div");
  overlay.id = "status-overlay";
  overlay.innerHTML =
    "<div><h1>Sorry, we are not <u>live</u> right now</h1><p>Please check us back at a later time</p></div>";

  var statusWrapper = $(".video-cover");
  if (!statusWrapper) {
    statusWrapper = $(".video-cover-wrapper");
  }

  statusWrapper.appendChild(overlay);

  $(".app").addClass("offline");
};

var unsetOffline = function () {
  var app = $(".app");
  app.addClass("online");
  app.removeClass("offline");
};

window.addEventListener("load", function () {
  var hasStopped = false;
  var isOffline = false;
  var retryTimeoutInt;
  var retryInterval = 6000;

  // var element = $(".theoplayer-container")[0];
  var videoUI = $("#video")[0];
  var playerSrcMime = 'application/x-mpegurl'

  /**@type {import('video.js').default.Player} */
  var videojsPlayer
  var playbackType = 'm3u8'
  var playbackURL = $('#player-url').val()
  var defaultPlaybackURLs = {
    live: 'https://live-hls-abr-cdn.livepush.io/live/bigbuckbunnyclip/index.m3u8',
    mpd: 'https://live-hls-abr-cdn.livepush.io/live/bigbuckbunnyclip/index.mpd',
    vod: 'https://live-hls-abr-cdn.livepush.io/vod/bigbuckbunnyclip.mp4',
  };

  var defaultPlaybackEmbedIds = {
    live: 'emvoh7TEXeR9c',
    vod: 'emvhF973nVsJM',
    mpd: 'emvDByP0wGq2q',
  };

  var embedId = null

  var setMediaSourceType = function (onkeyup) {
    if (playbackURL.endsWith('.mpd')) {
      playbackType = 'dash'
      playerSrcMime = 'application/dash+xml'
    } else if (playbackURL.endsWith('.mp4')) {
      playbackType = 'mp4'
      playerSrcMime = 'video/mp4'
    } else {
      playbackType = 'm3u8'
      playerSrcMime = 'application/x-mpegurl'
    }

    if (onkeyup !== true) {
      var dom = $('[data-playback-type=' + playbackType + ']')
      if (dom.length > 0) {
        $('[data-playback-type]').removeClass('active')
        dom.addClass('active')
      }
    }
  };

  $('#player-url').on('keyup', function () {
    // console.log('url-value', this.value, this)
    var url = this.value
    playbackURL = url

    setMediaSourceType(true)

    $("#extname").text(playbackType)
  });

  var onFailedToLoad = function () {
    setOffline();
    isOffline = true;
    hasStopped = true;
    // console.log("stopping player");
    videojsPlayer.pause();

    retryTimeoutInt = setTimeout(function () {
      // console.log("retrying play");
      videojsPlayer.play();
    }, retryInterval);
  };

  var onSuccessToLoad = function () {
    // if (isOffline) {
    unsetOffline();
    // }

    isOffline = false;
    clearTimeout(retryTimeoutInt);
  };

  var createVideoElement = function () {
    var video = document.createElement('video')
    video.className = 'video-js vjs-theme-city'
    video.controls = true
    video.crossorigin = true
    video.playsInline = true
    document.querySelector('.player-wrap').append(video)
    return video
  }

  var startPlayback = function (ignoreURLCompute) {

    $('.player-video-before').hide();

    // getEmbedId(function (embedId) {
    //   setEmbedLink(embedId)
    // })

    if (ignoreURLCompute !== true) {
      var url = $('#player-url').val()
      playbackURL = url
    }

    // if (playbackURL.endsWith('.mpd')) {
    //   playbackType = 'dash'
    //   playerSrcMime = 'application/dash+xml'
    // } else if (playbackURL.endsWith('.mp4')) {
    //   playbackType = 'mp4'
    //   playerSrcMime = 'video/mp4'
    // } else {
    //   playbackType = 'm3u8'
    //   playerSrcMime = 'application/x-mpegurl'
    // }
    setMediaSourceType()

    $("#extname").text(playbackType)

    if (videojsPlayer) {
      videojsPlayer.dispose();
      videoUI = createVideoElement();
    }

    // player = new THEOplayer.Player(element, {
    //   libraryLocation:
    //     "https://cdn.myth.theoplayer.com/8d6f7eb6-077c-40b6-a6fa-34e48aab1d08",
    //   license:
    //     "sZP7IYe6T6fzTDfLCLAg3Ok63LboFSaZCSf-TS3K36zk3K0oCDIl0KBL3S56FOPlUY3zWokgbgjNIOf9flXK3Kac3oh_FSbzCKf-3uCL06k6IlCLFSbz3LfiTSRcCDIeCmfVfK4_bQgZCYxNWoryIQXzImf90SCZ3S5z3Lfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lBr3LC_0ueLTuBLFOPeWok1dDrLYtA1Ioh6TgV6UQ1gWtAVCYggb6rlWoz6FOPVWo31WQ1qbta6FOfJfgzVfKxqWDXNWG3ybojkbK3gflNWfGxEIDjiWQXrIYfpCoj-f6i6WQjlCDcEWt3zf6i6v6PUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz",
    //   autoplay: true
    // });

    var options = {
      bigPlayButton: false,
      controls: true,
      // fluid: true,
      // muted: retryMuted === true,
      muted: true,
      autoplay: true,
      controlBar: {
        timeDivider: true,
        currentTimeDisplay: true,
        remainingTimeDisplay: false,
        playToggle: true,
        seekToLive: true,
        liveDisplay: true,
        volumePanel: {},
        fullscreenToggle: true,
        playbackRateMenuButton: false,
      },
      html5: {
        vhs: { experimentalBufferBasedABR: true, useDevicePixelRatio: true },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        useBandwidthFromLocalStorage: true
      },
      techOrder: ['chromecast', 'html5'],
      plugins: {
        // chromecast: {},
        httpSourceSelector: {
          default: 'low'
        },
      },
      sources: [
        {
          src: playbackURL,
          // type: 'application/x-mpegURL'
          type: playerSrcMime
        }
      ],
    };

    videojsPlayer = videojs(videoUI, options);
    videojsPlayer.volume(1)

    videojsPlayer.log = function () { }
    videojs.log.error = videojs.log.warn = videojsPlayer.log;

    var qualityLevels = videojsPlayer.qualityLevels()
    // console.log('qualityLevels', qualityLevels)
    videojsPlayer.httpSourceSelector();

    try {
      chromecastPlugin(videojs)
      videojsPlayer.chromecast();
    } catch (e) { console.log('cast plugin could not be loaded') }

    // console.log('setting up player')
    videojsPlayer.on("play", function () {
      // hasStarted = true;
      hasStopped = false;
      // hasPaused = false;
    });

    // console.log('player', videojsPlayer)
    var qualityLevelInit = false
    var setQualityLevel = function () {
      var qualityLevel = qualityLevels[qualityLevels.selectedIndex]
      if (qualityLevel) {
        var placeholderUI = $('.vjs-button.vjs-http-source-selector .vjs-icon-placeholder')
        if (placeholderUI) {
          qualityLevelInit = true
          // console.log('qualityLevel', qualityLevel)
          var height = qualityLevel.height
          var currentLabel = 'Auto'
          if (height) {
            // currentLabel += 'p'
            if (height >= 1920) {
              currentLabel = '4K'
            } else if (height >= 1440 && height < 1920) {
              currentLabel = '2K'
            } else if (height >= 1080 && height < 1440) {
              currentLabel = 'FHD'
            } else if (height >= 720 && height < 1080) {
              currentLabel = 'HD'
            } else {
              currentLabel = height + 'p'
            }

            currentLabel = currentLabel || (height + 'p')
            currentLabel = height + 'p'
          } else {
            // currentLabel = qualityLevel.bitrate
            var bitrate = qualityLevel.bitrate
            if (Number(bitrate)) {
              currentLabel = Math.round(bitrate / 1000) + 'K'
            } else {
              currentLabel = 'HD'
            }
          }

          placeholderUI.html(currentLabel);
          if (!placeholderUI.length) {
            setTimeout(function () {
              $('.vjs-button.vjs-http-source-selector .vjs-icon-placeholder').html(currentLabel);
            }, 1000);
          }
        }
      }
      console.log('qualityLevel', qualityLevel)
    }

    qualityLevels.on('change', setQualityLevel);

    var resizePLAYER = function () {
      var width = $('.player-wrap').width()
      $('.player-wrap').height(width / 1.7777777777)
      console.log('PLAYER', $('.player-wrap'), width)
    }

    resizePLAYER();
    $(window).on('resize', resizePLAYER)

  };

  var _send = XMLHttpRequest.prototype.send
  XMLHttpRequest.prototype.send = function () {
    _send.apply(this, arguments)

    // console.log('xhr-send-cb', this)
    this.addEventListener('readystatechange', function () {
      var statusCode = this.status;
      if (this.readyState === 4) {
        if (statusCode >= 200 && statusCode <= 302) {
          onSuccessToLoad();
        } else if (statusCode >= 400) {
          onFailedToLoad(url, statusCode);
        }
      }
    }, false);
  };

  var getEmbedId = function (cb) {
    var videoURL = $('#player-url').val()

    embedId = null
    if (videoURL === defaultPlaybackURLs.live) {
      embedId = defaultPlaybackEmbedIds.live
    } else if (videoURL === defaultPlaybackURLs.mpd) {
      embedId = defaultPlaybackEmbedIds.mpd
    } else if (videoURL === defaultPlaybackURLs.vod) {
      embedId = defaultPlaybackEmbedIds.vod
    }

    var ondone = function () {
      $('#embed-url-copy-btn').removeClass('form-btn-disabled');
      $('#embed-url-copy-btn').removeAttr('disabled');

      cb(embedId);
    }

    if (embedId) return ondone()

    $('#embed-url-copy-btn').addClass('form-btn-disabled');
    $('#embed-url-copy-btn').attr('disabled', 'disabled');
    setEmbedLink('Generating link..', true)

    axios
      .post('https://player-api.livepush.io/v1/embeds', { videoURL })
      // .post('http://127.0.0.1:4567/v1/embeds', { videoURL })
      .then(function (res) {
        embedId = res.data.embedId
      })
      .finally(ondone)
  }

  var setEmbedLink = function (embedId, isFullLink) {
    var embedURL = 'https://player.livepush.io/' + embedId;
    if (isFullLink) {
      embedURL = embedId
    }
    $('#embed-url').val(embedURL);
  }

  var copyEmbedCode = function (embedId) {
    var embedURLUI = $('#embed-url');
    // var embedURL = embedURLUI.val();
    embedURLUI.select()
    document.execCommand('copy');
  }

  window.HELP_IMPROVE_VIDEOJS = false;

  // disabling auto start on page load
  // startPlayback();

  // read src if in url
  const qs = parseQueryParams(window.location.search)
  if (qs && qs.src) {
    $('#player-url').val(qs.src)
  }

  // bind to button
  $('#player-play-btn').on('click', startPlayback)
  // $('#embed-url-copy-btn').on('click', copyEmbedCode)

  $('.form-btn.btn-type').on('click', function () {
    var btn = $(this)
    if (btn.hasClass('active')) return;

    btn.addClass('active');
    $(this).siblings().removeClass('active');

    var mediaType = this.dataset.playbackType
    if (playbackURL === defaultPlaybackURLs[mediaType]) true

    playbackURL = defaultPlaybackURLs[mediaType]
    $('#player-url').val(playbackURL)

    startPlayback(true)
  })
});
