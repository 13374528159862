// check and set up cookie consent
(function () {
  var cookieProp = 'accept_cookies';
  var cookieWidgetAppearDelay = 5000;

  if (window.localStorage.getItem(cookieProp) === '1') {
    onCookiesAccepted()
    return;
  }

  // var pathname = window.location.pathname
  // if (!pathname || !pathname.startsWith('/sign')) {
  //   return;
  // }

  var cookiebox = document.createElement('div');
  cookiebox.className = 'cookie-box';
  cookiebox.innerHTML =
    '<div class="message-wrap"><h2>This website uses cookies</h2><p>To give you a better experience, we may use cookies and other browser storage mainly for caching yoru login identity and behavioral statistics.</p><p>You can review our <a href="/privacypolicy/index.html" target="_blank">Privacy Policy</a> to learn more.</p></div>';

  var actionswrap = document.createElement('div');
  actionswrap.className = 'actions-wrap';

  var consentbtn = document.createElement('button');
  consentbtn.innerHTML = 'Accept Cookies';
  consentbtn.onclick = onCookiesAccepted;
  actionswrap.appendChild(consentbtn);
  cookiebox.appendChild(actionswrap);
  document.body.appendChild(cookiebox);

  function onCookiesAccepted() {
    window.localStorage.setItem(cookieProp, '1');

    if (window.gtag) {
      gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'analytics_storage': 'granted'
      });
    }
    hideCookieBox();
  }

  function showCookieBox() {
    if (!cookiebox) return;

    cookiebox.classList.add('visible');
  }

  function hideCookieBox() {
    if (!cookiebox) return;

    cookiebox.classList.remove('visible');
    setTimeout(() => {
      document.body.removeChild(cookiebox);
    }, 1000);
  }

  setTimeout(showCookieBox, cookieWidgetAppearDelay);
})();
